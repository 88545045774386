import { Component, input } from '@angular/core';

@Component({
  selector: 'priority',
  standalone: true,
  imports: [],
  template: `
    <div class="fw-600" [style.color]="getColor(name())">
      {{ name() }}
    </div>
  `,
  styles: ``
})
export class PriorityComponent {
  name = input<string>('black');

  getColor(prio: string) {
    if (prio == "High") return "#D40101";
    if (prio == "Medium") return "#B5781E";
    if (prio == "Low") return "#295AD8";
    return "black";
  }
}
