<div mat-dialog-title>
  <div class="justify-between">
    <h1 class="font-20px fw-600">
      {{ title }}
    </h1>
  </div>
</div>

<div mat-dialog-content>
  <ng-content select="[dialogContent]"></ng-content>
</div>

<mat-dialog-actions class="mat-dialog-roam-action pr-28">
  <div (click)="onCancel.emit()">
    <span class="text-underline font-14px fw-600 c-pointer">Cancel</span>
  </div>
  <app-roam-button [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
                   [label]="btnText"
                   [disabled]="isLoading"
                   [isLoading]="isLoading"
                   (onClick)="onSave.emit()"
  >
  </app-roam-button>
</mat-dialog-actions>

