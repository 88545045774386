import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelStatusComponent } from './label-status-icon/label-status.component';
import { PriorityComponent } from './priority/priority.component';
import { VerifiedStatusComponent } from './verified-status/verified-status.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LabelStatusComponent,
    VerifiedStatusComponent,
    PriorityComponent
  ],
  exports: [
    LabelStatusComponent,
    VerifiedStatusComponent,
    PriorityComponent
  ]
})
export class LabelStatusModule { }