import { Component, input } from '@angular/core';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';

@Component({
  selector: 'verified-status',
  standalone: true,
  imports: [RoamIconComponent],
  template: `
    <div class="status {{ status() }}">
      <roam-icon [src]="false" [name]="status() === 'verified' ? 'check' : 'close-red-round'" />
      <span>
        {{ status() === 'verified' ? 'Verified' : 'Unverified' }}
      </span>
    </div>
  `,
  styles: `
    .status {
      padding: 4px 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;

      &.verified {
        background-color: #E1F1E0;
        color: #155B0F;
      }

      &.unverified {
        background-color: #FDE8E8;
        color: #D50101;
      }
    }
  `
})
export class VerifiedStatusComponent {
  status = input<string>('unverified');
}
